import React /*, { useState }*/ from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/others/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

// seksjoner
import { SpacingSmall, SpacingMedium, SpacingLarge, DividerWaveTop, DividerWaveBottom } from "../components/sections/uiComponentRef";
import HeroLarge from "../components/sections/heroLarge";
import TextSection from "../components/sections/textSection";
import BlockContentSection from "../components/sections/blockContentSection";
import ImageGrid from "../components/sections/imageGrid";
import Accordions from "../components/sections/accordions";
import Hero from "../components/sections/hero";
import ContactForm from "../components/sections/contactForm";
import VideoSimple from "../components/sections/videoSimple";


export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title

      _rawFooterTextFirst(resolveReferences: { maxDepth: 5 })
      _rawFooterTextSecond(resolveReferences: { maxDepth: 5 })
      _rawFooterTextThird(resolveReferences: { maxDepth: 5 })

      openGraph {
        title
        description
        mainImage {
          ...SanityImage
          alt
        }
      }
    }
  }
`;

const Page = (props) => {
    const { data, errors } = props;
  
    if (errors) {
      return (
        <Layout>
          <GraphQLErrorList errors={errors} />
        </Layout>
      );
    }
  
    const site = (data || {}).site;
  
    if (!site) {
      throw new Error(
        'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
      );
    }

const page = data.page || data.route.page;

const content = (page._rawContent || [])
.filter((c) => !c.disabled)
.map((c, i) => {
  let el = null;
  switch (c._type) {
    case "heroLarge":
      el = <HeroLarge key={c._key} {...c} />;
      break;
    case "hero":
      el = <Hero key={c._key} {...c} />;
      break;
    case "contactForm":
      el = <ContactForm key={c._key} {...c} />;
      break;
    case "textSection":
      el = <TextSection key={c._key} {...c} />;
      break;
    case "imageGrid":
      el = <ImageGrid key={c._key} {...c} />;
      break;
    case "videoSimple":
      el = <VideoSimple key={c._key} {...c} />;
      break;
      case "accordionList":
        el = <Accordions key={c._key} {...c} />;
        break;
    case "blockContentSection":
      el = <BlockContentSection key={c._key} {...c} />;
      break;
      case "uiComponentRef":
          switch (c.name) {
              case "spacingSmall":
                  el = <SpacingSmall />;
                  break;
              case "spacingMedium":
                  el = <SpacingMedium />;
                  break;
              case "spacingLarge":
                  el = <SpacingLarge />;
                  break;
              case "dividerWaveTop":
                  el = <DividerWaveTop />;
                  break;
              case "dividerWaveBottom":
                  el = <DividerWaveBottom />;
                  break;
              default:
                  break; 
          }
          break;
      default:
          el = null;
  }
    return el;
});

const gradient = {
    from: (site.primaryColor && site.primaryColor.hex) || "#fff",
    to: (site.secondaryColor && site.secondaryColor.hex) || "#fff",
  };

const menuItems = page.navMenu && (page.navMenu.items || []);
const pageTitle = data.route && !data.route.useSiteTitle && page.title;


return (
    <Layout navMenuItems={menuItems}>
      <SEO
        title={pageTitle}
        description={site.description}
        keywords={site.keywords}
        bodyAttr={{
          class: "backgroundImage bodyAttrStyles",
        }}
        gradient={gradient}
      />
      <div>{content}</div>
    </Layout>
  );
};

export default Page;

/*
        bodyAttr={{
          class: "backgroundImage gradient bodyAttrStyles",
        }}
        */