import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
//import BlogPostPreviewList from "../components/blog/blog-post-preview-list";
import { ContainerComponentMusic } from "../components/container";
import GraphQLErrorList from "../components/others/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import styles from "./blog.module.css";
/*import BlockContent from "../components/sanity/block-content";*/
import BlogPostPreviewGrid from "../components/blog/blog-post-preview-grid";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
      }
    }

    blog: sanityBlogSettings(_id: { regex: "/(drafts.|)blogSettings/" }) {
      title
      displaySiteTitle
      _rawDescription(resolveReferences: { maxDepth: 5 })
      openGraph {
        title
        description
      }
    }
    

  route: sanityRoute {
    slug {
      current
    }
    useSiteTitle
    page {
      ...PageInfo
    }
  }

    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          bgcolor { 
            hex 
          }
          textcolor {
            hex
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const blog = (data || {}).blog;
  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  if (!blog) {
    console.warn(
      'Missing "Blog settings". Open the studio at http://localhost:3333 and add some content to "Blog settings" and restart the development server.'
    );
  }
  
  const page = data.page || data.route.page;
  const menuItems = page.navMenu && (page.navMenu.items || []);

  return (
    <Layout navMenuItems={menuItems}>
      <SEO title={blog.openGraph.title || 'Missing title'} description={blog.openGraph.description || 'Missing description'} keywords={site.keywords || []} />
      <ContainerComponentMusic>
        
        <div className={styles.wrapper}>
          <div style={{display: (blog.displaySiteTitle && blog.displaySiteTitle) || "block"}}>
            <h1 className={styles.heroHeading}>{blog.title}</h1>
          </div>
        </div>

        <div style={{paddingTop: '0rem'}}>{postNodes && <BlogPostPreviewGrid nodes={postNodes} />}</div>
      </ContainerComponentMusic>
    </Layout>
  );
};

export default IndexPage;

/* Brukes ikke

            <div className={styles.heroDescription}>
              <BlockContent blocks={blog._rawDescription} />
            </div>

*/