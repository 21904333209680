import React from "react";
import BlockContent from "../sanity/block-content";
import clientConfig from "../../../client-config";
import CTALink from "../CTALink";
import styles from "./hero.module.css";

import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
const maybeImage = (illustration) => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const fluidProps = getFluidGatsbyImage(
      illustration.image.asset._id,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = <img className={styles.img} src={fluidProps.src} alt={illustration.image.alt} />;
  }
  return img;
};

function Hero(props) {
  const img = maybeImage(props.illustration);
  return (
    <div style={{backgroundColor: (props.bgcolor && props.bgcolor.hex) || "transparent"}}>
      <div style={{color: (props.textcolor && props.textcolor.hex) || "black"}}>
        <div className={styles.wrapper}>
          {/* Left col */}
          <div className={styles.leftCol}>
            <h1 className={styles.leftColHeading}>{props.heading}</h1>
            <div className={styles.leftColPortableText}>
              <BlockContent blocks={props.tagline} />
            </div>
            <div className={styles.cta}>
              {(props.ctas || []).map((c, i) => (
                <CTALink key={`cta_${i}`} {...c} buttonActionClass={styles.button}/>
              ))}
            </div>
          </div>

          {/* Right col */}
          <div className={styles.rightCol}>
            {props.mainImage && props.mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.mainImage))
                .auto("format")
                .url()}
              alt={props.mainImage.alt}
            />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
