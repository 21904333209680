import React from "react";
import Header from "./header";
import Footer from "./footerSimple";
import "../styles/layout.css";

const Layout = ({
  children,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  displaySiteTitle,
  siteLogo,
  siteFooterTextFirst,
  siteFooterTextSecond,
  siteFooterTextThird,
  navMenuItems,
  social,
}) => (
  <>
    <Header
      siteTitle={siteTitle}
      social={social}
      siteLogo={siteLogo}
      displaySiteTitle={displaySiteTitle}
      navMenuItems={navMenuItems}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
    />
    <>{children}</>
  </>
);

export default Layout;

/*
 <Footer siteTitle={siteTitle} siteLogo={siteLogo} siteFooterTextFirst={siteFooterTextFirst} siteFooterTextSecond={siteFooterTextSecond} siteFooterTextThird={siteFooterTextThird}/>
 */
