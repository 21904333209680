import React, { useState } from "react";
import axios from "axios";
import { ContainerComponent } from "../container";
import styles from "./contactForm.module.css";

import BlockContent from "../sanity/block-content";
import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";

function ContactForm(props) {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/ae6798f8-4695-42a2-a085-1380169f7ed3",
      
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thank you! The message is sent.", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          backgroundColor:
            (props.bgcolor && props.bgcolor.hex) || "transparent",
        }}
      >
        <div
          style={{ color: (props.textcolor && props.textcolor.hex) || "black" }}
        ></div>
        <ContainerComponent>
          <h2>{props.heading}</h2>
          <form onSubmit={handleOnSubmit}>
            <div className={styles.formGroup}>
              <label
                className={styles.label}
                for="inputEmail"
                required="required"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                className={styles.formControl}
                id="inputEmail"
                aria-describedby="emailHelp"
                placeholder="Your email address"
                required="required"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} for="inputName">
                Name
              </label>
              <input
                type="text"
                name="name"
                className={styles.formControl}
                id="inputName"
                placeholder="Your name"
                required="required"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} for="inputMessage">
                Message
              </label>
              <textarea
                rows="4"
                type="text"
                name="message"
                className={styles.formControl}
                id="inputMessage"
                placeholder="Your message"
                required="required"
              />
            </div>


            {serverState.status && (
              <p
                style={{
                  marginTop: "1rem",
                  padding: "1rem",
                  borderWidth: "2px",
                  background: 'white',
                }}
                className={!serverState.status.ok ? "errorMsg" : ""}
              >
                {serverState.status.msg}
              </p>
            )}

            <button
              type="submit"
              className={styles.formButton}
              disabled={serverState.submitting}
            >
              Send
            </button>

          </form>
        </ContainerComponent>
      </div>
    </div>
  );
}

export default ContactForm;
