import React from "react";
import styles from "./videoSimple.module.css";
/*import { ContainerComponent } from "../container";*/


import ReactPlayer from 'react-player/lazy'
// import ReactPlayer from 'react-player/youtube' - Only loads the YouTube player


function VideoSimple(props) {

  return (
    /*<ContainerComponent>*/
        <section>
          <div className={styles.grid}>
            <div style={{ margin: (props.margin && props.margin) || "0"}}>
              <div className={styles.playerWrapper}>
                <ReactPlayer
                  className={styles.reactPlayer}
                  url={props.url}
                  width='100%'
                  height='100%'
                  controls={true}
                />
              </div>
              <figcaption>{props.caption}</figcaption>
            </div>
          </div>
        </section>
   /* </ContainerComponent>*/
  );
}

export default VideoSimple;