import { format } from "date-fns";
import { Link } from "gatsby";
import React from "react";
import { buildImageObj, cn, getBlogUrl } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import BlockContent from "../sanity/block-content";

import styles from "./blog-post-preview.module.css";
import { responsiveTitle3 } from "../typography.module.css";

function BlogPostPreview(props) {
  return (
    <div style={{background: props.bgcolor && props.bgcolor.hex, color: props.textcolor && props.textcolor.hex, padding: "40px"}}>
    <Link
      className={props.isInList ? styles.inList : styles.inGrid}
      to={getBlogUrl(props.slug.current)}
    >
      <div className={styles.gridContainer}>
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(1000)
              .height(Math.floor((1) * 1000))
              .auto("format")
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
      <div className={styles.text}>
        <h2 className={cn(responsiveTitle3, styles.title)}>{props.title}</h2>
        {props._rawExcerpt && (
          <div className={styles.excerpt}>
            <BlockContent blocks={props._rawExcerpt} />
          </div>
        )}
      </div>
      </div>
    </Link>
    </div>
  );
}

export default BlogPostPreview;
